import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiVercel,
  SiSplunk,
  SiCodeigniter,
  SiDebian,
  SiElasticsearch,
  SiGit,
  SiGitlab,
  SiGithub,
  SiJava,
  SiJavascript,
  SiKubuntu,
  SiLaravel,
  SiMicrosoft,
  SiMacos,
  SiNodedotjs,
  SiPowershell,
  SiPython,
  SiPhp,
  SiReact,
  SiSass,
  SiWireshark,
  SiWordpress
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSplunk />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
      </Col>
    </Row>
  );
}

export default Toolstack;
